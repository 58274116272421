import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faLightbulb,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-bootstrap";

const App = () => {
  const [salaryCLT, setSalaryCLT] = useState("");
  const [valeTransporte, setValeTransporte] = useState("");
  const [valeRefeicao, setValeRefeicao] = useState("");
  const [outrosBeneficios, setOutrosBeneficios] = useState("");
  const [aliquotaSimples, setAliquotaSimples] = useState("");
  const [result, setResult] = useState("");
  const [step, setStep] = useState('calculadora')
  const [resClt, setResClt] = useState(0)
  const [resPj, setResPj] = useState(0)

  function formatarParaReal(valor) {
    return valor.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });
}

  const getAliqAnexo3 = (rtb) => {
    let aliq = 0;
    let pd = 0

    if (rtb < 180000) {
      aliq = 6
      pd = 0
    } else if (rtb > 180000 && rtb <= 360000) {
      aliq = 11.2
      pd = 9360
    } else if (rtb >= 360000.01 && rtb <= 720000 ) {
      aliq = 13.5
      pd = 17640
    } else if (rtb >= 720000.01 && rtb <= 1800000) {
      aliq = 16
      pd = 35640
    } else if (rtb >= 1800000.01 && rtb <= 3600000){
      aliq = 21
      pd = 125640
    } else if (rtb >= 3600000.01 && rtb <= 4800000) {
      aliq = 33
      pd = 648000
    }

    return {
      aliq, pd
    }
    // return {
    //   aliq, pd
    // }
  }

  const getDesconto = () => {
      let rtb = salaryCLT * 12 // Valor ganho mensal multiplicado por 12 meses para ter o faturamento anual
      let anexo3 = getAliqAnexo3(rtb)

      let aliqEfetiva = ((rtb * (anexo3.aliq / 100)) - anexo3.pd) / rtb
      // let aliqEfetiva = ((360000 * (7.30 / 100)) - 5940) / 360000
      let valorDesconto = (rtb * (aliqEfetiva / 12))
      // console.log(valorDesconto, aliqEfetiva, (rtb * (aliqEfetiva / 12)))
    
      return valorDesconto
  }

  const handleCalculate = () => {

    const salarioCLT = parseFloat(
      (salaryCLT || "0").toString().replace(/[^\d.-]/g, "")
    );
    const descontoCLT = salarioCLT * 0.23;
    const beneficiosCLT =
      parseFloat((valeTransporte || "0").toString().replace(/[^\d.-]/g, "")) +
      parseFloat((valeRefeicao || "0").toString().replace(/[^\d.-]/g, "")) +
      parseFloat((outrosBeneficios || "0").toString().replace(/[^\d.-]/g, ""));

    const resultadoCLT = salarioCLT - descontoCLT + beneficiosCLT;

    // Cálculos para MEI
    const salarioMEI = salarioCLT;
    const descontoMEI = 75.6;
    const resultadoMEI = salarioMEI - descontoMEI;

    // Cálculos para Simples Nacional
    const aliquotaSimplesValue =
      parseFloat((aliquotaSimples || "0").toString()) / 100;
    const salarioSimples = salarioCLT;
    const descontoSimples = getDesconto();
    const resultadoSimples = salarioSimples - descontoSimples;

    const salarioEquivalenteMEI =
      (resultadoCLT + descontoMEI) / (1 - aliquotaSimplesValue);
    const salarioEquivalenteSimples =
      (resultadoCLT + descontoSimples) / (1 - aliquotaSimplesValue);

    setStep('resultado')
    setResClt(resultadoCLT)
    setResPj(resultadoSimples)

    setResult(
      `<table class="table table-bordered">
          <thead>
            <tr>
              <th>Comparativo CLT e PJ</th>
              <th>CLT</th>
              <th>PJ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Salário</td>
              <td>R$ ${salarioCLT.toFixed(2)}</td>
              <td>R$ ${salarioCLT.toFixed(2)}</td>
            </tr>
            <tr>
              <td>Descontos</td>
              <td>- R$ ${descontoCLT.toFixed(2)}</td>
              <td>- R$ ${descontoSimples.toFixed(2)}</td>
            </tr>
            <tr>
              <td>Benefícios</td>
              <td>R$ ${beneficiosCLT.toFixed(2)}</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Resultado</td>
              <td>R$ ${resultadoCLT.toFixed(2)}</td>
              <td>R$ ${resultadoSimples.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
  
        `
    );
  };

  // <h3>Remuneração Líquida Equivalente ao Salário CLT</h3>
  //       <table class="table table-bordered">
  //         <thead>
  //           <tr>
  //             <th>CLT</th>
  //             <th>PJ</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           <tr>
  //             <td>R$ ${salarioCLT.toFixed(2)}</td>
  //             <td>R$ ${salarioEquivalenteSimples.toFixed(2)}</td>
  //           </tr>
  //         </tbody>
  //       </table>

  useEffect(() => {
    document.title = "Calculadora CLT x PJ";
  }, []);

  return (
    <>
      <div
        style={{
          backgroundImage:
            "url('https://www.contabilidadeolimpia.com.br/wp-content/uploads/2023/02/1014-scaled.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="bg-primary text-white min-vh-100 d-flex flex-column justify-content-center"
      >
        <div className="container text-center">
          {/* Logo centralizada */}
          <div className="mb-4">
            <img
              src="https://www.contabilidadeolimpia.com.br/wp-content/uploads/2023/02/LOGO-BRANCA-1024x331.png"
              alt="Logo"
              className="img-fluid"
              style={{ width: 350 }}
            />
          </div>

          <div className="row mb-5">
            <div className="col-lg-8 col-sm-12 mb-5">
              <div className="card shadow-lg p-4 rounded">
                <h2 className="mb-4">
                  Simule sua Renda - Comparativo CLT x PJ
                </h2>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      Salário Mensal/Bruto (R$)
                    </label>
                    <input
                      type="number"
                      step={0.01}
                      value={salaryCLT}
                      onChange={(e) => setSalaryCLT(e.target.value)}
                      className="form-control"
                      placeholder="Exemplo: 3.000,00"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Vale Transporte (R$)</label>
                    <input
                      type="number"
                      step={0.01}
                      value={valeTransporte}
                      onChange={(e) => setValeTransporte(e.target.value)}
                      className="form-control"
                      placeholder="Exemplo: 150,00"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Vale Refeição (R$)</label>
                    <input
                      type="number"
                      step={0.01}
                      value={valeRefeicao}
                      onChange={(e) => setValeRefeicao(e.target.value)}
                      className="form-control"
                      placeholder="Exemplo: 300,00"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Outros Benefícios (R$)</label>
                    <input
                      type="number"
                      step={0.01}
                      value={outrosBeneficios}
                      onChange={(e) => setOutrosBeneficios(e.target.value)}
                      className="form-control"
                      placeholder="Exemplo: 100,00"
                    />
                  </div>
                  {/* <div className="col-md-6 mb-3">
                    <label className="form-label">
                      Alíquota do Simples Nacional (%)
                    </label>
                    <input
                      type="number"
                      step={0.01}
                      className="form-control"
                      value={aliquotaSimples}
                      onChange={(e) => setAliquotaSimples(e.target.value)}
                      placeholder="Exemplo: 16"
                    />
                  </div> */}
                </div>
                <button
                  className="btn btn-primary w-100 mb-4"
                  onClick={handleCalculate}
                >
                  Calcular
                </button>
                <div
                  className="result-container mt-4"
                  dangerouslySetInnerHTML={{ __html: result }}
                />
                {step === 'resultado' &&
                  <Row
                  className="mt-5"
                  style={{ padding: '15px' }}
              >
                  <div className="cta">
                      <Row>
                          <Col
                              md={8}
                              className="text-area"
                          >
                              Na Contabilidade Olímpia,
                              você pode economizar{' '}
                              <span className="txt-economizar">
                                  {formatarParaReal(
                                    resPj - resClt 
                                  )}
                              </span>{' '}
                              em impostos neste cálculo se
                              emitir notas fiscais com um
                              CNPJ.
                          </Col>
                          <Col
                              md={4}
                              className="action-area"
                          >
                              <a
                                  href="https://api.whatsapp.com/send?phone=5511968591004&text=Preciso%20tirar%20d%C3%BAvida%20sobre%20o%20CNPJ"
                                  target="_blank"
                                  className="btn-cta"
                              >
                                  Quero economizar!
                              </a>
                          </Col>
                      </Row>
                  </div>
              </Row>
                }
              </div>
            </div>
            <div className="col-lg-4 col-sm-12" >
              <div
                className="card shadow-lg p-4 rounded custom-gradient"
                style={{
                  background: "linear-gradient(to bottom, #7cceee, #ffffff)",
                  marginBottom: '270px'
                }}
              >
                <h1>Abra seu CNPJ grátis com a Olímpia!</h1>
                <div>
                  <p style={{ marginBottom: "-10px", fontWeight: 600 }}>
                    Pague
                  </p>
                  <span style={{ fontSize: "40", fontWeight: 700 }}>
                    {" "}
                    SÓ AS TAXAS
                  </span>
                </div>
                <a
                  href="https://api.whatsapp.com/send?phone=5511968591004&text=Gostaria%20de%20abrir%20meu%20CNPJ"
                  style={{ marginTop: "20px" }}
                  target="_blank"
                  className="btn btn-primary"
                >
                  QUERO SER PJ
                </a>
                <ul
                  style={{
                    listStyle: "none",
                    textAlign: "left",
                    marginTop: "20px",
                  }}
                >
                  <li style={{ marginTop: "3px" }}>
                    <FontAwesomeIcon icon={faCheck} color="#1b93c3" size={25} />{" "}
                    Contratando um de nossos planos de contabilidade,{" "}
                    <b>a abertura do seu CNPJ é grátis</b>
                  </li>
                  <li style={{ marginTop: "3px" }}>
                    <FontAwesomeIcon icon={faCheck} color="#1b93c3" size={25} />{" "}
                    <b>Otimizamos os impostos</b> do seu CNPJ para a sua
                    remuneração render.
                  </li>
                  <li style={{ marginTop: "3px" }}>
                    <FontAwesomeIcon icon={faCheck} color="#1b93c3" size={25} />{" "}
                    Emita notas fiscais e acompanhe sua empresa{" "}
                    <b>24h por dia</b> na nossa plataforma
                  </li>
                  <li style={{ marginTop: "3px" }}>
                    <FontAwesomeIcon icon={faCheck} color="#1b93c3" size={25} />{" "}
                    Escolha a forma de atendimento:{" "}
                    <b>WhatsApp, telefone, chat ou e-mail</b>
                  </li>
                  <li style={{ marginTop: "3px" }}>
                    <FontAwesomeIcon icon={faCheck} color="#1b93c3" size={25} />{" "}
                    <b>Planos com assessor que entende sua área</b> e emite suas
                    notas fiscais
                  </li>
                  {/* <li style={{marginTop: '3px'}}><FontAwesomeIcon icon={faCheck} color="#1b93c3" size={25}/> Contrate <b>planos de saúde até 30% mais baratos do que os para pessoa física</b></li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom-cta-area">
                <div className="container">
                    <Row>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <div className="container container-cust">
                                <div style={{ display: 'flex'}}>
                                    <div 
                                        className="hide-mobile"
                                        style={{ width: 100 }}>
                                        <FontAwesomeIcon
                                            icon={faLightbulb}
                                            color="#1b93c3"
                                            size="3x"
                                        />
                                    </div>
                                    <div
                                        className="hide-mobile"
                                        style={{
                                            textAlign: 'left',
                                            width: 200,
                                            color: '#414141',
                                        }}
                                    >
                                        Acessoria especializada no seu
                                        seguimento
                                    </div>
                                    <div
                                        className='cta-btn-area'
                                        style={{
                                            display: 'flex',
                                            flex: 2,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <a
                                            target="_blank"
                                            href="https://www.contabilidadeolimpia.com.br/faleconoscocontato#nossos-planos"
                                            style={{
                                                fontWeight: 700,
                                                color: '#1b93c3',
                                                borderWidth: '0.5px',
                                                borderColor: '#1b93c3',
                                                borderStyle: 'solid',
                                                padding: '10px 30px',
                                                borderRadius: 25,
                                            }}
                                        >
                                            Conheça nossos planos
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <div className="container container-cust">
                                <div 
                                    style={{ display: 'flex' }}>
                                    <div
                                        className="hide-mobile" 
                                        style={{ width: 100 }}>
                                        <FontAwesomeIcon
                                            icon={faPhone}
                                            color="#1b93c3"
                                            size="3x"
                                        />
                                    </div>
                                    <div
                                        className="hide-mobile"
                                        style={{
                                            textAlign: 'left',
                                            width: 200,
                                            color: '#414141',
                                        }}
                                    >
                                        <span style={{ fontWeight: 600 }}>
                                            Dúvidas?
                                        </span>
                                        <br />
                                        Entre em contato
                                    </div>
                                    <div
                                        className='cta-btn-area'
                                        style={{
                                            display: 'flex',
                                            flex: 2,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <a
                                            href="https://www.contabilidadeolimpia.com.br/faleconoscocontato/"
                                            target="_blank"
                                            style={{
                                                fontWeight: 700,
                                                color: '#fff',
                                                borderWidth: '0.5px',
                                                background: '#1b93c3',
                                                borderStyle: 'solid',
                                                padding: '10px 30px',
                                                borderRadius: 25,
                                            }}
                                        >
                                            Fale com um especialista
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
    </>
  );
};

export default App;
// #1b93c3
